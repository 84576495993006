<template>
    <div>
        <b-card border-variant="danger" v-if="errore_caricamento">
          <b-row class="match-height">
            <b-col
              md="8"
            >
              <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
              <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
            </b-col>
            <b-col 
              md="4"
              class="text-center"
            >
              <b-button
              variant="primary"
              :to="{ name: 'crm-agenti-index' }"
              >
                  <feather-icon
                      icon="CornerUpLeftIcon"
                      size="16"
                      class="mr-1"
                  />Lista Agenti
              </b-button>
            </b-col>
          </b-row>
            
        </b-card>

        <div class="text-center my-3" v-if="Caricato == false">
            <b-spinner  v-if="errore_caricamento == false"
                variant="primary"
                style="width: 3rem; height: 3rem;"
                label="loading"
            />
            <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
        </div>

        <div v-if="Caricato">
            <b-card>
                <b-card-header class="pt-0 pl-0 pb-25">
                <b-card-title>
                    {{ campiform.agent.name }} {{ campiform.agent.surname }} 
                    <b-badge v-if="campiform.agent.deleted_at !== null" variant="light-danger" v-b-tooltip.hover.v-danger title="Agente cancellato">
                        <feather-icon icon="XIcon" />
                    </b-badge>
                    <b-badge v-else variant="light-primary" v-b-tooltip.hover.v-primary title="Agente attivo">
                        <feather-icon icon="CheckIcon" />
                    </b-badge>
                </b-card-title>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    class="btn-icon rounded-circle"
                    id="`sidebar-azioni`"
                    @click="sidebarActionShow(campiform.agent.id, campiform.agent.name+' '+campiform.agent.surname, campiform.agent.deleted_at)"
                >
                    <feather-icon icon="ArrowRightIcon" />
                </b-button>
                </b-card-header>

                <b-card-body class="px-0 py-0">
                    <b-row>
                        <b-col
                        cols="21"
                        xl="6"
                        >
                        
                            <!--
                            <h3 class="pb-50 mb-0">Mario Rossi</h3>
                            -->
                            <p class="pb-50 mb-0">
                                <a href="#" class="card-text">{{ campiform.agent.email }}</a><br />
                                <a href="#" class="card-text">{{ campiform.agent.mobile_phone }}</a>             
                            </p>
                            <p class="pb-50 mb-0">
                                
                                <span class="card-text">{{ campiform.agent.nome_gender }}</span><br /> 
                                <span class="card-text">nato il {{ formattazioneData(campiform.agent.birthday, 'it') }}</span>    
                            </p>

                        </b-col>
                        
                        <b-col
                        cols="12"
                        xl="6"
                        >
                        <table class="mt-2 mt-xl-0 w-100">
                            <tr>
                            <th>
                                <span class="font-weight-bold">Città</span>
                            </th>
                            <td>
                                {{ campiform.agent.city }} ({{ campiform.agent.nome_provincia }})
                            </td>
                            </tr>
                            <tr>
                            <th>
                                <span class="font-weight-bold">Allineamento</span>
                            </th>
                            <td>
                                <b-badge variant="light-primary">{{ campiform.agent.nome_align }}</b-badge>
                            </td>
                            </tr>
                            <tr>
                            <th>
                            <span class="font-weight-bold">P.Iva</span>
                            </th>
                            <td>
                                <b-badge v-if="campiform.agent.vat === true" variant="light-primary">
                                    <feather-icon icon="CheckIcon" />
                                </b-badge>
                                <b-badge v-else variant="light-danger">
                                    <feather-icon icon="XIcon" />
                                </b-badge>
                            </td>
                            </tr>
                            <tr>
                            <th>
                                <span class="font-weight-bold">Enasarco</span>
                            </th>
                            <td>
                                <b-badge v-if="campiform.agent.enasarco === true" variant="light-primary">
                                    <feather-icon icon="CheckIcon" />
                                </b-badge>
                                <b-badge v-else variant="light-danger">
                                    <feather-icon icon="XIcon" />
                                </b-badge>
                            </td>
                            </tr>
                        </table>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>

            <b-sidebar
                ref="sidebar_azioni"
                id="sidebar-right"
                bg-variant="white"
                :title="infoSideBar.titolo"
                right
                backdrop
                shadow
                >
                <div>
                    <div class="py-2 px-2">
                        <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-warning" block
                            class="mb-1"
                            @click="$router.push({ name: 'crm-agenti-edit', params: { id_riga: infoSideBar.id }})"
                        >
                            <feather-icon icon="Edit2Icon" /> Modifica
                        </b-button>
                        <b-button
                            v-if="infoSideBar.deleted_at"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-warning" block
                            class="mb-1"
                            @click="ripristinaRiga(infoSideBar.id)"
                        >
                            <feather-icon icon="RefreshCcwIcon" /> Riattiva agente
                        </b-button>
                        <b-button
                            v-else
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-danger" block
                            class="mb-1"
                            @click="cancellaRiga(infoSideBar.id)"
                        >
                            <feather-icon icon="Trash2Icon" /> Cancella
                        </b-button>

                    </div>
                </div>
            </b-sidebar>

            <b-tabs pills>
                <b-tab active>
                    <template #title>
                        <feather-icon
                        icon="UserIcon"
                        size="18"
                        class="mr-50"
                        />
                        <span class="font-weight-bold">Dettagli</span>
                    </template>

                    <b-card>
                        <b-row>
                            <b-col cols="12">
                            <showdata field_name="Indirizzo" :field_value="campiform.agent.address" />
                            </b-col>
                            <b-col md="6" lg="4">
                            <showdata field_name="Cap" :field_value="campiform.agent.zip" />
                            </b-col>
                            <b-col md="6" lg="4">
                            <showdata field_name="Città" :field_value="campiform.agent.city" />
                            </b-col>
                            <b-col md="6" lg="4">
                            <showdata field_name="Provincia" :field_value="campiform.agent.nome_provincia" />
                            </b-col>
                            
                            <b-col md="12" lg="12">
                            <showdata field_name="Provenienza" :field_value="campiform.agent.nome_provenance" />
                            </b-col>
                            

                            <b-col md="12" lg="8">
                                <showdata field_name="Note" :field_value="campiform.agent.note" />
                            </b-col>

                            <b-col md="6" lg="2" v-if="campiform.agent.cv_1 !== null">
                                <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                block
                                variant="outline-primary"
                                @click="donwloadCV(campiform.agent.cv_1)"
                                >
                                Ultimo CV
                                </b-button>
                            </b-col>
                            <b-col md="6" lg="2" v-if="campiform.agent.cv_2 !== null">
                                <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                block
                                variant="outline-primary"
                                @click="donwloadCV(campiform.agent.cv_2)"
                                >
                                Precedente CV
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>

                <b-tab>
                    <template #title>
                        <feather-icon
                        icon="CheckSquareIcon"
                        size="18"
                        class="mr-50"
                        />
                        <span class="font-weight-bold">Skills</span>
                    </template>

                    <b-card>
                        <b-row>
                            <b-col cols="12">
                                <showdata field_name="Profili" :field_value="formattazioneArray(campiform.agent_profiles)" /></b-col>
                            <b-col cols="12">
                                <showdata field_name="Regioni di operatività" :field_value="formattazioneArray(campiform.agent_regions)" />
                            </b-col>
                            <b-col cols="12">
                                <showdata field_name="Settori" :field_value="formattazioneArray(campiform.agent_sectors)" />
                            </b-col>
                            <b-col cols="12">
                                <showdata field_name="Segmenti" :field_value="formattazioneArray(campiform.agent_experiences.segmenti)" />
                            </b-col>
                            <b-col cols="12">
                                <showdata field_name="Aziende" :field_value="formattazioneArray(campiform.agent_experiences.aziende)" />
                            </b-col>
                            <b-col md="4" lg="3">
                            <showdata field_name="Titolo di studio" :field_value="campiform.agent.degree.text" />
                            </b-col>
                            <b-col md="4" lg="3">
                            <showdata field_name="Lingua madre" :field_value="campiform.agent.nome_native_lang" />
                            </b-col>
                            <b-col md="4" lg="3">
                            <showdata field_name="Altre lingue" :field_value="formattazioneArray(campiform.agent_langs)" />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>

                <b-tab @click="onTabChangeEsperienze">
                    <template #title>
                        <feather-icon
                        icon="LayersIcon"
                        size="18"
                        class="mr-50"
                        />
                        <span class="font-weight-bold">Esperienze</span>
                    </template>

                    <b-card>
                        <div class="text-right">
                            <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            class="mb-1"
                            @click="sidebarEsperienza('Nuova Esperienza','','','','','','','','','','','')"
                            >
                            <feather-icon icon="PlusIcon" /> Inserisci Nuova Esperienza
                            </b-button>
                        </div>

                        <b-overlay :show="show_loader_esperienze" rounded="md" style="min-height:343px;">
                            <app-timeline>
                                <app-timeline-item v-for="riga in list_esperienze" 
                                    :key="riga.id"
                                    icon="LayersIcon"
                                    class="riga_over"
                                    @click="sidebarEsperienza('Modifica Esperienza',riga.id,riga.year_start,riga.year_end,riga.note,riga.id_company,riga.nome_azienda,riga.id_sector,riga.nome_settore,riga.id_profession,riga.nome_professione)">
                                    <b-row>
                                        <b-col md="6">
                                        <showdata field_name="Professione" :field_value="riga.nome_professione" />
                                        </b-col>
                                        <b-col md="6">
                                        <showdata field_name="Settore" :field_value="riga.nome_settore" />
                                        </b-col>
                                        <b-col md="3">
                                        <showdata field_name="Anno inizio" :field_value="riga.year_start" />
                                        </b-col>
                                        <b-col md="3">
                                        <showdata field_name="Anno fine" :field_value="riga.year_end" />
                                        </b-col>
                                        <b-col md="6">
                                        <showdata field_name="Azienda" :field_value="riga.nome_azienda" />
                                        </b-col>
                                        <b-col cols="12" v-if="riga.note !== null">
                                        <showdata field_name="Note" :field_value="riga.note" />
                                        </b-col>
                                    </b-row>
                                </app-timeline-item>

                            </app-timeline>
                        </b-overlay>

                    </b-card>
                </b-tab>

                <b-tab>
                    <template #title>
                        <feather-icon
                        icon="BookmarkIcon"
                        size="18"
                        class="mr-50"
                        />
                        <span class="font-weight-bold">Candidature</span>
                    </template>

                    <b-card>
                        <h1>NON FARE - detto da Andrea il 24/11 in call</h1>
                        faremo un'impaginazione simile alle esperienze utilizzando la struttura a timeline -> la faremo più avanti quando faremo le ricerche.
                        <hr />
                        <app-timeline>
                            <app-timeline-item icon="BookmarkIcon">
                                Data Candidatura + Nome Azienda + Codice Ricerca (cliccabile che rimanda appunto alla ricerca d'agente fatta della suddetta azienda)
                            </app-timeline-item>
                            <app-timeline-item icon="BookmarkIcon">
                                due
                            </app-timeline-item>
                            <app-timeline-item icon="BookmarkIcon">
                                ... n
                            </app-timeline-item>
                        </app-timeline>

                    </b-card>
                </b-tab>

                <b-tab>
                    <template #title>
                        <feather-icon
                        icon="SettingsIcon"
                        size="18"
                        class="mr-50"
                        />
                        <span class="font-weight-bold">Stato</span>
                    </template>

                    <b-card>
                        <b-row>
                            <b-col md="4" lg="3">
                            <showdata field_name="Registrazione utente" :field_value="formattazioneData(campiform.agent.datatime_agent_reg, 'it', 'DD/MM/YYYY - HH:mm:ss')" />
                            </b-col>
                            <b-col md="4" lg="3">
                            <showdata field_name="Ultima attività" :field_value="formattazioneData(campiform.agent.datetime_last_agent_action, 'it', 'DD/MM/YYYY - HH:mm:ss')" />
                            </b-col>
                            <b-col md="4" lg="3">
                            <showdata field_name="Ultimo aggiorn. CV" :field_value="formattazioneData(campiform.agent.datetime_last_cv_updated, 'it', 'DD/MM/YYYY - HH:mm:ss')" />
                            </b-col>
                            <b-col md="4" lg="3">
                            <showdata field_name="Ultima valutazione" :field_value="formattazioneData(campiform.agent.datetime_last_update_by_user, 'it', 'DD/MM/YYYY - HH:mm:ss')" />
                            </b-col>
                            
                            <b-col md="4" lg="3">
                                <dl>
                                    <dt class="text-muted">Newsletter</dt>
                                    <dd>
                                        <b-badge variant="light-primary">
                                            Iscritto
                                        </b-badge>
                                    </dd>
                                </dl>
                            </b-col>
                            <b-col md="4" lg="3">
                                <dl>
                                    <dt class="text-muted">Email bounce</dt>
                                    <dd>
                                        <b-badge variant="light-primary" v-if="campiform.agent.email_bounce === false">
                                            No
                                        </b-badge>
                                        <b-badge variant="light-danger" v-else>
                                            No
                                        </b-badge>
                                    </dd>
                                </dl>
                            </b-col>

                        </b-row>
                    </b-card>
                </b-tab>

                <b-tab @click="onTabChangeCommenti">
                    <template #title>
                        <feather-icon
                        icon="BookmarkIcon"
                        size="18"
                        class="mr-50"
                        />
                        <span class="font-weight-bold">Commenti</span>
                    </template>

                    <b-card>
                        <div class="text-right">
                            <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            class="mb-1"
                            @click="sidebarCommento('Nuovo Commento','','')"
                            >
                            <feather-icon icon="PlusIcon" /> Inserisci Commento
                            </b-button>
                        </div>
                        
                        <b-overlay :show="show_loader_commenti" rounded="sm">
                            <div class="comments-list">
                                <vue-perfect-scrollbar
                                class="scroll-area"
                                :settings="perfectScrollbarSettings"
                                >
                                <ul class="list-unstyled">
                                <li v-for="riga in list_comments"
                                    :key="riga.id"
                                    class="d-flex justify-content-between align-items-center riga" 
                                    @click="sidebarCommento('Modifica Commento',riga.id,riga.content)">
                                    <div v-html="riga.content"></div>
                                    <div class="text-muted dx">{{ riga.author }}<br /><span class="blockquote-footer">{{ formattazioneDataOra(riga.update_at, 'it') }}</span></div>
                                </li>
                                </ul>
                                
                                </vue-perfect-scrollbar>
                            </div>
                        </b-overlay>

                    </b-card>
                </b-tab>
            </b-tabs>

            <b-sidebar
                ref="sidebar_commenti"
                id="sidebar-commenti"
                bg-variant="white"
                :title="SideBarCommenti.tipo_operazione"
                :visible="sidebar_visibile"
                right
                backdrop
                shadow
            >
            <div>
                <div class="py-0 px-2">
                    <validation-observer ref="simpleRules">
                        <b-form>
                        <b-form-group
                            label="Commento"
                            label-for="task-description"
                        >
                            <validation-provider
                            #default="{ errors }"
                            name="Commento"
                            rules="required"
                            >
                            <quill-editor
                                id="quil-content"
                                v-model="campiform_commenti.content"
                                :options="editorOption"
                                class="border-bottom-0"
                                style="height: 250px;"
                            />
                            <div
                                id="quill-toolbar"
                                class="d-flex justify-content-end border-top-0"
                            >
                                <!-- Add a bold button -->
                                <button class="ql-bold" />
                                <button class="ql-italic" />
                                <button class="ql-underline" />
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <div class="mt-2">
                            <b-button
                            variant="outline-primary"
                            type="submit"
                            @click.prevent="validationFormCommento()"
                            >
                            Salva
                            </b-button>
                            <b-button
                            v-if="SideBarCommenti.tipo_operazione == 'Modifica Commento'"
                            variant="outline-danger" 
                            class="ml-2"
                            type="submit"
                            @click.prevent="CancellaCommento(SideBarCommenti.id_commento)"
                            >
                            Cancella
                            </b-button>
                        </div>
                        </b-form>
                    </validation-observer>
                </div>
            </div>
            </b-sidebar>

            <b-sidebar
                ref="sidebar_esperienze"
                id="sidebar-esperienze"
                bg-variant="white"
                :title="SideBarEsperienze.tipo_operazione"
                :visible="sidebar_esperienze_visibile"
                right
                backdrop
                shadow
            >
            <div>
                <div class="py-0 px-2">
                    <b-overlay :show="show_loader_form_esperienze" rounded="md" style="height:495px;">
                        <div v-if="errore_caricamento_form_esperienze">
                            <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
                            <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
                        </div>

                        <div v-if="Caricato_Sidebar_form_esperienze">
                            <validation-observer ref="simpleRules_esperienze">
                                <b-form>

                                    <b-form-group
                                        label="Professione *"
                                        label-for="v-adse-professione"
                                    >
                                        <validation-provider
                                        #default="{ errors }"
                                        name="Professione"
                                        rules="required"
                                        >
                                            <multiselect
                                            v-model="campiform_esperienze.professione"
                                            :options="optionsProfessioni"
                                            :custom-label="multiSelect_act_Lingua" 
                                            placeholder="seleziona o ricerca"
                                            :multiple="false"
                                            :close-on-select="true" 
                                            :clear-on-select="false"
                                            :preserve-search="true"
                                            :show-labels="false"
                                            :max-height="250"
                                            :max="1"
                                            group-values="items"
                                            group-label="groupname" 
                                            :group-select="false"
                                            label="professione" 
                                            track-by="text" 
                                            >
                                            <template #optiongroup="slotProps">
                                                <div>{{ slotProps.option.label }}</div>
                                            </template>
                                            <span slot="noResult">Nessun risultato</span>
                                            <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                                            </multiselect>

                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>

                                    <b-form-group
                                        label="Settore *"
                                        label-for="v-adse-settore"
                                    >
                                        <validation-provider
                                        #default="{ errors }"
                                        name="Settore"
                                        rules="required"
                                        >
                                            <multiselect
                                            v-model="campiform_esperienze.settore"
                                            :options="optionsSettori"
                                            :custom-label="multiSelect_act_Lingua" 
                                            placeholder="seleziona o ricerca"
                                            :multiple="false"
                                            :close-on-select="true" 
                                            :clear-on-select="false"
                                            :preserve-search="true"
                                            :show-labels="false"
                                            :max-height="250"
                                            :max="1"
                                            group-values="items"
                                            group-label="groupname" 
                                            :group-select="false"
                                            label="settore" 
                                            track-by="text" 
                                            >
                                            <template #optiongroup="slotProps">
                                                <div>{{ slotProps.option.label }}</div>
                                            </template>
                                            <span slot="noResult">Nessun risultato</span>
                                            <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                                            </multiselect>

                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>

                                    <b-form-group
                                        label="Azienda *"
                                        label-for="v-adse-azienda"
                                    >
                                        <validation-provider
                                        #default="{ errors }"
                                        name="Azienda"
                                        rules="required"
                                        >
                                            <multiselect 
                                            v-model="campiform_esperienze.azienda" 
                                            id="azienda" 
                                            label="azienda" 
                                            track-by="value" 
                                            placeholder="ricerca" 
                                            open-direction="bottom" 
                                            :options="lista_aziende" 
                                            :multiple="true" 
                                            :searchable="true" 
                                            :loading="isLoadingAziende" 
                                            :internal-search="true" 
                                            :clear-on-select="false" 
                                            :close-on-select="true" 
                                            :max="1"
                                            :options-limit="10"
                                            :max-height="250" 
                                            :show-no-results="false" 
                                            :hide-selected="false" 
                                            :show-labels="false" 
                                            :custom-label="multiSelect_act_Lingua" 
                                            @search-change="asyncFindAziende">
                                                <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                                                <span slot="noResult">Nessun risultato</span>
                                            </multiselect>

                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>

                                    <b-row>
                                        <b-col md="6">
                                            <b-form-group label="Anno inizio *" label-for="year_start">
                                                <validation-provider
                                                #default="{ errors }"
                                                name="Anno inizio"
                                                rules="required|digits:4"
                                                >
                                                    <b-form-input
                                                    v-model="campiform_esperienze.year_start"
                                                    placeholder="YYYY"
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Anno fine *" label-for="year_end">
                                                <validation-provider
                                                #default="{ errors }"
                                                name="Anno fine"
                                                rules="required|digits:4"
                                                >
                                                    <b-form-input
                                                    v-model="campiform_esperienze.year_end"
                                                    placeholder="YYYY"
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-form-group label="Note" label-for="note">
                                        <b-form-textarea 
                                            id="textarea-note"
                                            rows="3"
                                            v-model="campiform_esperienze.note"
                                        />
                                    </b-form-group>

                                    <div class="mt-2">
                                        <b-button
                                        variant="outline-primary"
                                        type="submit"
                                        @click.prevent="validationFormEsperienza()"
                                        >
                                        Salva
                                        </b-button>
                                        <b-button
                                        v-if="SideBarEsperienze.tipo_operazione == 'Modifica Esperienza'"
                                        variant="outline-danger" 
                                        class="ml-2"
                                        type="submit"
                                        @click.prevent="CancellaEsperienza(SideBarEsperienze.id_esperienza)"
                                        >
                                        Cancella
                                        </b-button>
                                    </div>
                                </b-form>
                            </validation-observer>
                        </div>
                    </b-overlay>
                </div>
            </div>
            </b-sidebar>
    
        </div>
    </div>
</template>

<script>
import axios from '@axios'
import router from '@/router'
import {
  BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BFormGroup, BForm, BFormInput, BFormTextarea, BButton, BBadge, BSidebar, VBToggle, BAvatar, BRow, BCol, BTabs, BTab, VBTooltip, BOverlay
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import showdata from '@core/components/showdata/showdata.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  required
} from '@validations'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Multiselect from 'vue-multiselect' 


export default {
  components: {
    BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BFormGroup, BForm, BFormInput, BFormTextarea, BButton, 
    BBadge, BSidebar, VBToggle, BRow, BCol, BAvatar, BTabs, BTab, VBTooltip, BOverlay,

    showdata,
    AppTimeline,
    AppTimelineItem,
    VuePerfectScrollbar,

    ValidationProvider,
    ValidationObserver,
    localize,
    it,
    quillEditor,
    Multiselect,
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    localize('it')
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
        perfectScrollbarSettings: '',
        editorOption: {
            modules: {
            toolbar: '#quill-toolbar',
            },
            placeholder: 'scrivi commento ...',
        },
        campiform: [ ],
        infoSideBar: {
            id: '',
            titolo: '',
        },
        Caricato: false,
        errore_caricamento: false,

        show_loader_commenti: true, 
        list_comments: { },
        
        serverParams: {
            columnFilters: {
            "deleted_at": {"operator": "=", "value": null }
            },
            sort: [
                {
                field: 'created_at',
                type: 'desc'
                }
            ],
            page: 1, 
            perPage: 50
        },
        
        campiform_commenti: {
            id_card: '',
            tipo_operazione: 'new'
        },
        campiform_commenti_start: {
            content: '',
            tipo_operazione: 'new'
        },
        sidebar_visibile: false,
        SideBarCommenti: { 
            tipo_operazione: '', 
            id_card: '', 
            id_esperienza: '' 
        },
        required,

        show_loader_esperienze: true, 
        list_esperienze: { },

        show_loader_form_esperienze: true,
        Caricato_Sidebar_form_esperienze: false,
        errore_caricamento_form_esperienze: false,
        optionsSettori: [ ], 
        optionsProfessioni: [ ], 
        lista_aziende: [],
        isLoadingAziende: false,
        campiform_esperienze: {
            id_agente: '', 
            id_esperienza: '', 
            year_start: '', 
            year_end: '', 
            note: '', 
            professione: { },
            settore: { },
            azienda: { },
            tipo_operazione: 'new'
        },
        campiform_esperienze_start: {
            id_agente: '', 
            id_esperienza: '', 
            year_start: '', 
            year_end: '', 
            note: '', 
            professione: {
                text: '',
                value: '',
            },
            settore: {
                text: '',
                value: '',
            },
            azienda: [{
                text: '',
                value: '',
            }],
            tipo_operazione: 'new'
        },
        sidebar_esperienze_visibile: false,
        SideBarEsperienze: { 
            tipo_operazione: '', 
            id_esperienza: '', 
        },
    }
  }, 
  created() { 
    const schedaAgenteBase = this.$http.get('api/crm/agents/schedabase/'+router.currentRoute.params.id_riga)

    Promise.all([schedaAgenteBase]).then(response => {
        if(response[0].data.statusCode===200){
            //caricamento dati scheda Agente
            this.campiform = response[0].data.reply

            this.Caricato = true;
            this.errore_caricamento = false;

        } else {
            this.Caricato = false;
            this.errore_caricamento = true;
        }
    });
  },
  methods: {
    sidebarActionShow(id, titolo, deleted_at) {
        //console.log("id -> "+id+"\ntitolo -> "+titolo);
        this.infoSideBar = { id: id, titolo: titolo, deleted_at: deleted_at };

        this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },    
    formattazioneData(created_at,lang,format_data = 'DD/MM/YYYY') {
      if (created_at) {
        return moment(String(created_at)).locale(lang).format(format_data)
      } else {
        return '---'
      }
    },
    formattazioneArray(ArrayPassato) {
      return ArrayPassato.map(obj => obj.text).join(', ');
    },
    donwloadCV(nome_file){
        //console.log("file -> "+nome_file)

        axios({
            method: 'get',
            url: 'api/crm/agents/getpdf/' + nome_file,
            responseType: 'arraybuffer', // Indica che si tratta di un array di byte
        })
        .then(response => {
          if(response){
            //risposta positiva
            //console.log(response)

            // Crea un blob con i dati ricevuti e apri il file in una nuova finestra
            const blob = new Blob([response.data], {type: response.headers['content-type']});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', nome_file);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

          } else {
            //risposta negativa (errore sul server)
            this.$swal({
                icon: 'error',
                title: 'Si è verificato un errore generico',
                text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })
          }
        }).catch(e => {
          console.log(e);
        });
    },
    ripristinaRiga(id_riga){
      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la riattivazione dell'agente",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/agents/recover/'+id_riga)
            .then(response => { 
                //console.log("risposta server --------")
                //console.log(response.data)

                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                      icon: 'success',
                      title: response.data.reply.reply,
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })

                  //chiudi sidebar
                  this.$refs.sidebar_azioni.hide();

                  //aggiorna stato utente
                  this.campiform.agent.deleted_at = null;
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },    
    cancellaRiga(id_riga){
      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la cancellazione dell'agente",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/agents/delete/'+id_riga)
            .then(response => { 
                //console.log("risposta server --------")
                //console.log(response.data)

                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                      icon: 'success',
                      title: response.data.reply.reply,
                      //text: 'operazione effettuata correttamente',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })

                  //chiudi sidebar
                  this.$refs.sidebar_azioni.hide();

                  //aggiorna stato utente
                  this.campiform.agent.deleted_at = 1; //valore fittizzio (basta che sia diverso da "null")
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
    onTabChangeCommenti(){
        this.show_loader_commenti = true;

        this.$http.get('api/crm/comments/agent/list/'+this.campiform.agent.id, {
            params: this.serverParams 
        }).then(response => { 
            if(response.data.statusCode == 200){
                //ok
                this.list_comments = response.data.reply.data
                this.show_loader_commenti = false; 
            
            } else if(response.data.statusCode == 422){
                //errori con testo di notifica ok dall'api
                this.list_comments = []
                this.show_loader_commenti = false; 

                this.$swal({
                    title: 'Errore caricamento Commenti',
                    text: 'codice errore: '+response.data.reply,
                    icon: 'error',
                    customClass: {
                    confirmButton: 'btn btn-primary',
                    textButton: 'Chiudi',
                    },
                    buttonsStyling: false,
                })
            } else {
                //tutto gli altri possibili errori
                this.list_comments = []
                this.show_loader_commenti = false; 
                
                this.$swal({
                    title: 'Errore Generico caricamento Commenti',
                    text: 'codice errore: '+response.data.statusCode,
                    icon: 'error',
                    customClass: {
                    confirmButton: 'btn btn-primary',
                    textButton: 'Chiudi',
                    },
                    buttonsStyling: false,
                })
            }
        
        })
    },
    sidebarCommento(tipo_operazione, id_commento, testo_commento){
      this.SideBarCommenti = { tipo_operazione: tipo_operazione, id_card: this.campiform.agent.id, id_commento: id_commento };

      if( tipo_operazione == 'Nuovo Commento' ){
        this.campiform_commenti.tipo_operazione = 'new'
        this.campiform_commenti.content = ''
        this.campiform_commenti.id_commento = ''
      } else {
        this.campiform_commenti.tipo_operazione = 'edit'
        this.campiform_commenti.content = testo_commento
        this.campiform_commenti.id_commento = id_commento
      }

      this.$root.$emit('bv::toggle::collapse', 'sidebar-commenti')
    },
    validationFormCommento(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line

          //c'è da aggregare altre informazioni da inviare all'api per creare l'utente ???
          console.log(this.campiform_commenti)

          let url_api = '';
          if( this.campiform_commenti.tipo_operazione == 'new' ){
            url_api+='api/crm/comments/agent/card/'+this.campiform.agent.id
          } else {
            url_api+='api/crm/comments/agent/card/'+this.campiform.agent.id+'/'+this.campiform_commenti.id_commento;
          }

          this.$http.post(url_api, 
            this.campiform_commenti 
            ).then(response => {
              if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
                //risposta positiva
                if(response.data.reply.commentData.id > 0){
                  //salvataggio effettuato + aggiorna lista commenti (ultimo salvato primo della lista)
                  if( this.campiform_commenti.tipo_operazione == 'new' ){
                    //aggiungi alla lista
                    this.list_comments.unshift(response.data.reply.commentData)

                  } else {
                    //modifica -> aggiorna relativa voce della lista
                    this.list_comments = this.list_comments.map(object => {
                      if (object.id == response.data.reply.commentData.id) {
                        return {...object,
                          content: response.data.reply.commentData.content,
                          update_at: response.data.reply.commentData.update_at,
                          author: response.data.reply.commentData.author};
                      }
                      return object;
                    });
                  }

                  //chiudo finestra ...
                  this.$refs.sidebar_commenti.hide();
                  //azzero form commento
                  this.campiform_commenti = this.campiform_commenti_start

                  //reset validation form
                  this.$refs.simpleRules.reset();

                  this.$swal({
                      icon: 'success',
                      title: 'Nuovo commento salvato correttamente',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })
                } else {
                  //questo non dovrebbe mai accadere ... ma non si sa mai -> visualizzo risposta ma non faccio redirect
                  this.$swal({
                      icon: 'warning',
                      title: 'Si è verificato un errore',
                      text: 'Salvataggio riuscito ma con errore '+response.data.statusCode+', ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }

              } else {
                //risposta negativa (errore sul server)
                this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore',
                    text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                    customClass: {
                    confirmButton: 'btn btn-outline-primary',
                    },
                })
              }
            }).catch(e => {
              console.log(e);
            });

        }
      })
    },
    CancellaCommento(id_riga_commento){
      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la cancellazione definitiva del commento?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/comments/agent/delete/'+this.campiform.agent.id+'/'+id_riga_commento)
            .then(response => { 
                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                      icon: 'success',
                      title: response.data.reply.reply,
                      //text: 'operazione effettuata correttamente',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })

                  //chiudi sidebar
                  this.$refs.sidebar_commenti.hide();

                  //rimuovi il commento eliminato dalla lista
                  this.list_comments = this.list_comments.filter(riga => riga.id != id_riga_commento);
                    
                } else {
                 //chiudi sidebar
                 this.$refs.sidebar_commenti.hide();

                 //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
    formattazioneDataOra(created_at,lang) {
      if (created_at) {
        return moment(String(created_at)).locale(lang).format('DD/MM/YYYY - HH:mm:ss')
      } else {
        return '---'
      }
    },
    ApiEsperienze(){
        this.$http.get('api/crm/agents/experiences/list/'+this.campiform.agent.id).then(response => { 
            if(response.data.statusCode == 200){
                //ok
                this.list_esperienze = response.data.reply
                this.show_loader_esperienze = false; 
            
            } else if(response.data.statusCode == 422){
                //errori con testo di notifica ok dall'api
                this.list_esperienze = []
                this.show_loader_esperienze = false; 

                this.$swal({
                    title: 'Errore caricamento Esperienze',
                    text: 'codice errore: '+response.data.reply,
                    icon: 'error',
                    customClass: {
                    confirmButton: 'btn btn-primary',
                    textButton: 'Chiudi',
                    },
                    buttonsStyling: false,
                })
            } else {
                //tutto gli altri possibili errori
                this.list_esperienze = []
                this.show_loader_esperienze = false; 
                
                this.$swal({
                    title: 'Errore Generico caricamento Esperienze',
                    text: 'codice errore: '+response.data.statusCode,
                    icon: 'error',
                    customClass: {
                    confirmButton: 'btn btn-primary',
                    textButton: 'Chiudi',
                    },
                    buttonsStyling: false,
                })
            }
        
        })
    },
    onTabChangeEsperienze(){        
        this.show_loader_esperienze = true;

        this.ApiEsperienze();        
    },
    multiSelect_act_Lingua ({ value, text }) {
      return `${text}`
    },
    sidebarEsperienza(tipo_operazione, id_esperienza, year_start, year_end, note, id_company, nome_campany, id_sector, nome_sector, id_profession, nome_profession){

        console.log("nome_sector -> "+nome_sector+" ("+id_sector+")")

      this.SideBarEsperienze = { 
        tipo_operazione: tipo_operazione, 
        id_esperienza: id_esperienza, 
      };

      if( tipo_operazione == 'Nuova Esperienza' ){
        this.campiform_esperienze.tipo_operazione = 'new'
        this.campiform_esperienze.id_agente = this.campiform.agent.id
        this.campiform_esperienze.id_esperienza = ''
        this.campiform_esperienze.year_start = '' 
        this.campiform_esperienze.year_end = ''
        this.campiform_esperienze.note = '' 
      } else {
        this.campiform_esperienze.tipo_operazione = 'edit'
        this.campiform_esperienze.id_agente = this.campiform.agent.id
        this.campiform_esperienze.id_esperienza = id_esperienza
        this.campiform_esperienze.year_start = year_start
        this.campiform_esperienze.year_end = year_end
        this.campiform_esperienze.note = note
        this.campiform_esperienze.azienda.value = id_company 
        this.campiform_esperienze.azienda.text = nome_campany
        this.campiform_esperienze.professione.value = id_profession
        this.campiform_esperienze.professione.text = nome_profession
        this.campiform_esperienze.settore.value = id_sector
        this.campiform_esperienze.settore.text = nome_sector
      }

      //this.show_loader_form_esperienze = true;

      this.$root.$emit('bv::toggle::collapse', 'sidebar-esperienze')

      const SettoriPromise = this.$http.get('api/crm/agents/select/settore')
      const professioniPromise = this.$http.get('api/crm/agents/select/professione')

      Promise.all([SettoriPromise,professioniPromise]).then(response => {
        if(response[0].data.statusCode===200){
          //caricamento lista Settori
          this.optionsSettori = response[0].data.reply

          if(response[1].data.statusCode===200){
            //caricamento lista professioni
            this.optionsProfessioni = response[1].data.reply

            this.Caricato_Sidebar_form_esperienze = true;
            this.errore_caricamento_form_esperienze = false;
            this.show_loader_form_esperienze = false;

          } else {
            this.Caricato_Sidebar_form_esperienze = false;
            this.errore_caricamento_form_esperienze = true;
            this.show_loader_form_esperienze = false;
          }
        } else {
          this.Caricato_Sidebar_form_esperienze = false;
          this.errore_caricamento_form_esperienze = true;
         this.show_loader_form_esperienze = false;
        }
      })
    },
    asyncFindAziende(query_aziende) {
      //console.log("asyncFindAziende ...");
      let aziende_ricerca_ok = query_aziende.trim();

      if((aziende_ricerca_ok !== '') && (aziende_ricerca_ok.length >= 3)){    
        //si parte
        this.isLoadingAziende = true
        
        //azzero le option dell'autosuggest dei cap
        this.lista_aziende = [ ];

        this.$http.get('api/crm/agents/select/azienda/'+aziende_ricerca_ok).then(response => { 
          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva
            this.isLoadingAziende = false;

            this.lista_aziende = response.data.reply;
            //console.log(this.lista_aziende)
          } else {
            //risposta negativa (errore sul server)
            if(response.data.reply.message != ''){
              this.$swal({
                  icon: 'warning',
                  title: 'Si è verificato un errore',
                  text: 'nella ricerca delle aziende',
                  customClass: {
                  confirmButton: 'btn btn-outline-primary',
                  },
              })
            } else {
              this.$swal({
                  icon: 'error',
                  title: 'Si è verificato un errore generico',
                  text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                  customClass: {
                  confirmButton: 'btn btn-outline-primary',
                  },
              })
            }

            this.isLoadingAziende = false;
          }
        }).catch(e => {
          console.log(e);
          this.isLoadingAziende = false;
        });
      }

    },   
    validationFormEsperienza(){
       this.$refs.simpleRules_esperienze.validate().then(success => {
        if (success) {
            console.log(this.campiform_esperienze)

            //1. chimata api per salvare i dati
            let url_api = '';
            if( this.campiform_commenti.tipo_operazione == 'new' ){
                url_api+='api/crm/agents/experiences/card/'+this.campiform.agent.id
            } else {
                url_api+='api/crm/agents/experiences/card/'+this.campiform.agent.id+'/'+this.campiform_commenti.id_commento;
            }

            this.$http.post(url_api, 
            this.campiform_esperienze 
            ).then(response => {
            if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
                //risposta positiva
                if(response.data.reply != ''){
                //salvataggio effettuato + aggiorna lista commenti (ultimo salvato primo della lista)
                
                //2. refresh lista Esperienze
                this.ApiEsperienze();

                //3. chiudi sidebar
                this.$refs.sidebar_esperienze.hide();

                //4. sfuota campi form
                this.campiform_esperienze = this.campiform_esperienze_start;

                //reset validation form
                this.$refs.simpleRules_esperienze.reset();

                this.$swal({
                    icon: 'success',
                    title: response.data.reply.reply,
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                })
                } else {
                //questo non dovrebbe mai accadere ... ma non si sa mai -> visualizzo risposta ma non faccio redirect
                this.$swal({
                    icon: 'warning',
                    title: 'Si è verificato un errore',
                    text: 'Salvataggio riuscito ma con errore '+response.data.statusCode+', ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                    customClass: {
                    confirmButton: 'btn btn-outline-primary',
                    },
                })
                }

            } else {
                //risposta negativa (errore sul server)
                this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore',
                    text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                    customClass: {
                    confirmButton: 'btn btn-outline-primary',
                    },
                })
            }
            }).catch(e => {
            console.log(e);
            });
            
        }
      })
    },
    CancellaEsperienza(id_riga_experience){
      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la cancellazione definitiva dell\'esperienza ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/agents/experiences/delete/'+this.campiform.agent.id+'/'+id_riga_experience)
            .then(response => { 
                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                      icon: 'success',
                      title: response.data.reply.reply,
                      //text: 'operazione effettuata correttamente',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })

                  
                    //2. refresh lista Esperienze
                    this.ApiEsperienze();

                    //3. chiudi sidebar
                    this.$refs.sidebar_esperienze.hide();

                    //4. sfuota campi form
                    this.campiform_esperienze = this.campiform_esperienze_start;

                    //reset validation form
                    this.$refs.simpleRules_esperienze.reset();
                    
                } else {
                    //1. refresh lista Esperienze
                    this.ApiEsperienze();

                    //2. chiudi sidebar
                    this.$refs.sidebar_esperienze.hide();

                    //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-multiselect.min.scss';
  .comments-list .scroll-area {
    position: relative;
    margin: auto;
    padding: 10px;
    width: 100%;
    height: 450px;
    border: 1px solid #eee;
  }
  .comments-list .scroll-area ul li.riga{
    border-bottom: 1px solid #eee;
    padding: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: all 0.2s, border-color 0s;
    position: relative;
  }
  .comments-list .scroll-area ul li.riga:hover{
    transform: translateY(-4px);
    box-shadow: 0 3px 10px 0 #ebe9f1;
  }
  .comments-list .scroll-area ul li.riga:last-child{
    border-bottom: none;
  }
  .comments-list .scroll-area ul li.riga .dx{
    min-width: 150px;
    margin-left: 25px;
    text-align: right;
  }

  .riga_over{
    cursor: pointer;
    margin-bottom: 5px;
    padding: 10px 10px 0 10px;
  }
  .riga_over:hover{
    transform: translateY(-4px);
    box-shadow: 0 3px 10px 0 #ebe9f1;
  }
</style>